@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&family=Lateef&family=Markazi+Text:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700;900&display=swap");
/************************************/
$colors: (
  orangeLight: #f7eccc,
  orangeDark: #f7a420,
  redLight: #cd250e,
  redDark: #801e08,
  greenLight: #1ecd0e,
  greenDark: #086b10,
  white: #ffffff,
  black-100: #000000,
  black-90: #121318,
  black-80: #131313,
  black-70: #232228,
  black-60: #37343b,
  black-50: #53505b,
  success: #5bcc5b,
  danger: #f521c0,
  neon: #26ddff,
  neonDanger: #f521c0,
);

// //gets a color value from color map, defined in colorDefinitions
@function color($colorName) {
  @return map-get($colors, $colorName);
}

html {
  font-size: 16px;
  background-color: rgb(2, 6, 26);
}

input[readonly] {
  cursor: text;
  background-color: transparent !important;
}

.rtl {
  direction: rtl !important;
}

.rtl .rtlFont,
.rtl.rtlFont {
  font-family: "Markazi Text", serif;
}

.Persian {
  font-family: "El Messiri", sans-serif;
}

.Messiri {
  font-family: "El Messiri", sans-serif;
}

.Karla {
  font-family: "Karla", sans-serif !important;
}

.right-0 {
  right: 0 !important;
}

.right-auto {
  right: auto !important;
}

.left-0 {
  left: 0 !important;
}

.left-auto {
  left: auto !important;
}

.w-max {
  width: max-content !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  // border                 : 1px solid #fff !important;
  -webkit-text-fill-color: #f0ce89 !important;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

div.MuiPopover-root {
  z-index: 9999999 !important;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  // font-family: "El Messiri", sans-serif;
  // font-family: "Lateef", cursive;
  // font-family: "Markazi Text", serif;
  // font-weight: 600 !important;
  background-color: #000 !important;
  font-size: 1rem;
  color: #fff;
  min-height: 100vh;
}

body::-webkit-scrollbar {
  width: 6px;
  /* width of the entire scrollbar */
  background: rgb(0, 0, 0);
}

body::-webkit-scrollbar-thumb {
  background-color: black;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 4px solid #464646;
  /* creates padding around scroll thumb */
}

/* 1.25 dpr */
@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  /* Retina-specific stuff here */
  body {
    // font-size: 24px;
  }
}

/* 1.3 dpr */
@media (-webkit-min-device-pixel-ratio: 1.3), (min-resolution: 124.8dpi) {
  /* Retina-specific stuff here */
}

/* 1.5 dpr */
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  /* Retina-specific stuff here */
}

@media screen and (min-width: 1920px) {
  // and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.89) // and (min-resolution: 192dpi)
  html {
    font-size: 1vw;
  }
}

// @media (min-device-width: 5000px) {
//   // and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.89) // and (min-resolution: 192dpi)

//   html {
//     font-size: 1vw;
//   }
// }

/* Chrome, Safari, Edge, Opera */
.noArrow input::-webkit-outer-spin-button,
.noArrow input::-webkit-inner-spin-button,
.noArrow input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.text-primary {
  color: color(neon) !important;
}

.border-primary {
  border: 1px solid color(neon);
}

.box-shadow-primary {
  box-shadow: 0px 0px 4px color(neon);
}

.text-shadow-primary {
  text-shadow: 0px 0px 4px color(neon);
}

.text-gray {
  color: #aaa;
}

.text-warning {
  color: #f4b740 !important;
}

.btn {
  text-transform: none;
}

nav.navbar {
  background-color: #121318;
  box-shadow: 0px 0px 5px #000;
}

*,
.noScrollbar,
.MuiDrawer-root.MuiDrawer-docked > div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar,
.noScrollbar::-webkit-scrollbar,
.MuiDrawer-root.MuiDrawer-docked > div::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
}

.noSelect {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.yesselect,
.yesselect span,
.yesselect p,
.yesselect div,
.yesselect strong,
.yesselect td,
.yesselect li {
  -webkit-touch-callout: text !important;
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#Crash .SpaceIcon {
  position: absolute;
  bottom: 2%;
  left: 2%;
  cursor: pointer;
}

#Crash #Payout {
  position: absolute;
  bottom: 5%;
  right: 5%;
  font-weight: normal;
  transform: translate(0%, 0%);
  color: #fff;
  line-height: 1em;
}

#Crash #Disconnected {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
}

#WaitingCounter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  font-weight: bold;
}

.primary-gradient {
  background-image: linear-gradient(to right, #7d1cca, #000);
}

/*
* 	BIG Jelly Button
*		Original source from Mathias Adam:
*				- https://dribbble.com/shots/6209185-BIG-Jelly-Button
*
*		I tried to stay as true to the original source as possible.
*		Unforutunately there were a few effects I couldn't make 
*		happen with CSS. Primarily the suble noise texture. I 
*		considered using a base64 encoded noise pattern overlay but
*		I felt like that was cheating. I also couldn't do the
*		true bevel effect on the label since CSS doesn't have an
*		inset text shadow option. 
*/

button.jelly {
  width: 100%;
  cursor: pointer;
  position: relative;
  padding: 0.5rem 0;
  border-radius: 0.4rem !important;
  line-height: 1.5rem;
  font-size: 1rem;
  /* font-weight  : 600; */
  border: none;
  background-image: linear-gradient(-180deg, #8c00ff 0%, #9000a9 100%);
  overflow: hidden;
}

button.jelly-green {
  background-image: linear-gradient(-180deg, var(--bs-success) 0%, var(--bs-green) 100%);
}

button.jelly-orange {
  background-image: linear-gradient(-180deg, var(--bs-warning) 0%, var(--bs-orange) 100%);
}

button.jelly-red {
  background-image: linear-gradient(-180deg, var(--bs-danger) 0%, var(--bs-red) 100%);
}

button.jelly-small {
  padding: 0.5rem 0.5rem;
  line-height: 0.5rem;
  font-size: 0.6rem;
}

button.jelly-group {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

button.jelly-group * {
  margin: auto;
}

button.jelly span {
  color: #fff;
  /* color                  : transparent; */
  /* background-image       : linear-gradient(0deg, #EE82DA 0%, #FEFAFD 100%); */
  /* -webkit-background-clip: text; */
  /* background-clip        : text; */
  /* filter                 : drop-shadow(0 2px 2px hsla(290, 100%, 20%, 1)); */
}

button.jelly::before {
  content: "";
  display: block;
  height: 0.15rem;
  position: absolute;
  top: 0.2rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;
  opacity: 0.25;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: 0.3s;
}

button.jelly:active {
  opacity: 0.8;
}

button.jelly:active::before {
  opacity: 0;
}

button.jelly::after {
  content: "";
  display: block;
  height: 10.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 100%;
  transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;
  filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

button.jelly:disabled {
  opacity: 0.4;
  box-shadow: inset 0px 0px 1px #000;
}

button.jelly:disabled::before {
  display: none;
}

button.jelly:hover {
  opacity: 0.95;
}

button.jelly:hover::before {
  top: 100%;
  height: 10%;
  opacity: 0.1;
}

// button.jelly:hover::after {
//   left: 0%;
//   opacity: 0.4;
// }
.detailsBtn i {
  transition: all 0.3s ease-in-out;
}

.detailsBtn.active i {
  transform: rotate(90deg);
}

.numeral-input {
  background-color: #fff1 !important;
  outline: none !important;
  color: #fff !important;
  padding: 4px;
  border-radius: 5px;
  width: 100%;
}

.numeral-input.large {
  font-size: 1.2em;
}

canvas {
  height: 100 !important;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.375rem 1rem 0.3125rem 1rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
}

.grecaptcha-badge {
  display: none !important;
}

.adminPanelDrawerItem {
  border-left: 5px solid red;
  font-size: 0.9em;
}

.adminPanelDrawerItem span {
  font-size: 1.2em;
  color: #ddd;
}

// @media (min-width: 768px) {
//   .adminPanelDrawerItem span {
//     font-size: 1.1em;
//   }
// }
.adminPanelDrawerItem.active {
  border-left: 5px solid #f4b740;
  background-color: #171717;
}

.adminPanelDrawerItem:hover {
  background-color: #171717 !important;
}

#AdminDrawer div[data-id] {
  background-color: #232228;
}

.adminPanelDrawerSubItem {
  opacity: 0.7;
  border-left: 1px solid #fff !important;
  margin-left: 20px !important;
}

.adminPanelDrawerSubItem.active {
  color: #f4b740;
  opacity: 1 !important;
}

.neon-blink-info {
  animation: boxShadowBlink-neon 1s infinite;
}

@keyframes boxShadowBlink-neon {
  50% {
    text-shadow: 0px 0px 15px color(neon) !important;
    filter: drop-shadow(0px 0px 1px #fff);
  }
}

.trace-motion {
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  opacity: 0.8;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

// main {
//   margin: 4em auto 8em;
//   max-width: 1200px;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
// }

.spell-card {
  &__shape {
    margin-top: 20px;

    svg {
      width: 70%;
      max-width: 250px;
      filter: drop-shadow(0 0 12px #0ff) drop-shadow(-2px -2px 12px #00bcd4);
    }
  }

  &__trace {
    position: absolute;
    width: 100%;
    opacity: 0.4;
    height: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center;
  }

  &:hover {
    cursor: pointer;

    .trace-motion {
      animation: draw 0.7s linear forwards;
    }
  }
}

// .SnackbarContainer-bottom {
//   bottom: 5rem !important;
// }

.lobbyBanner img {
  transition: all 0.2s ease-in-out;
}

.lobbyBanner img:first-child {
  filter: drop-shadow(0px 0px 0.2rem #fb0);
}

.lobbyBanner:hover img:first-child {
  filter: drop-shadow(0px 0px 0.5rem #26ddff);
}

.lobbyBanner img:nth-child(2) {
  // filter: drop-shadow(0px 0px  1px #26ddff);
}

.lobbyBanner:hover img:nth-child(2) {
  transform: scale(1.05, 1.05);
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
}

.lobbyBanner svg {
  transition: all 0.2s ease-in-out;
}

.lobbyBanner:hover svg {
  transition: all 0.2s ease-in-out;
  transform: scale(1.05, 1.05);
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
}

.lobbyBanner.poker:hover svg [data-name="Stripes"],
.lobbyBanner.poker:hover svg #Stripes {
  transform-origin: center center;
  transform-box: fill-box;
  animation: rotating 10s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// .lobbyBanner #Chip1{
//   transform-origin: center center;
//   transform-box: fill-box;
//    animation: flips 2s linear infinite;
// }

// @keyframes flips {
//   0%{
//       transform: rotateZ(30deg) rotateY(0deg);
//       opacity: 1;
//   }
//   24.9%{
//     opacity: 1;

//   }
//   25%{
//     opacity: 0;
//   }
//   74.9%{
//     opacity: 0;

//   }
//   75%{
//     opacity: 1;

//   }
//   100%{
//       transform: rotateZ(30deg) rotateY(360deg);
//       opacity: 1;
//   }
// }
.lobbyBanner path {
  transition: all 1s ease;
}

/* set fill for before and for during hover */
//  path { fill: transparent; }
.lobbyBanner:hover #Club_Daddy path:first-child {
  stroke: #26ddff;
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
  transform: translateX(-5%);
}

.lobbyBanner:hover #Club_Daddy path:last-child {
  fill: #26ddff !important;
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
  transform: translateX(-5%);
}

/*********************************************/
.lobbyBanner #Play_Online path:first-child {
  transition: all 1.5s 0.05s ease;
  //  fill: linear-gradient(45deg, red 0 50%, blue 50% 100%) !important;
}

//  <stop offset="0.17" stop-color="#a2781c"></stop>
//  <stop offset="0.35" stop-color="#e9be5a"></stop><
//  stop offset="0.62" stop-color="#fddd9e"></stop>
//  <stop offset="0.78" stop-color="#e6c57e"></stop>
//  <stop offset="1" stop-color="#a2781c"></stop>

.lobbyBanner:hover #Play_Online path:first-child {
  stroke: #26ddff;
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
  fill: #000 !important;
  transform: translateX(5%);
}

// P
.lobbyBanner #Play_Online path:nth-child(2) {
  transition: all 1s 0.05s ease;
}

.lobbyBanner:hover #Play_Online path:nth-child(2) {
  fill: #26ddff !important;
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
  transform: translateX(5%);
}

// L
.lobbyBanner #Play_Online path:nth-child(3) {
  transition: all 1s 0.1s ease;
}

.lobbyBanner:hover #Play_Online path:nth-child(3) {
  fill: #26ddff !important;
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
  transform: translateX(5%);
}

// A
.lobbyBanner #Play_Online path:nth-child(4) {
  transition: all 1s 0.15s ease;
}

.lobbyBanner:hover #Play_Online path:nth-child(4) {
  fill: #26ddff !important;
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
  transform: translateX(5%);
}

// Y
.lobbyBanner #Play_Online path:nth-child(5) {
  transition: all 1s 0.2s ease;
}

.lobbyBanner:hover #Play_Online path:nth-child(5) {
  fill: #26ddff !important;
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
  transform: translateX(5%);
}

// O
.lobbyBanner #Play_Online path:nth-child(6) {
  transition: all 1s 0.25s ease;
}

.lobbyBanner:hover #Play_Online path:nth-child(6) {
  fill: #26ddff !important;
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
  transform: translateX(5%);
}

// N
.lobbyBanner #Play_Online path:nth-child(7) {
  transition: all 1s 0.3s ease;
}

.lobbyBanner:hover #Play_Online path:nth-child(7) {
  fill: #26ddff !important;
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
  transform: translateX(5%);
}

// L
.lobbyBanner #Play_Online path:nth-child(8) {
  transition: all 1s 0.35s ease;
}

.lobbyBanner:hover #Play_Online path:nth-child(8) {
  fill: #26ddff !important;
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
  transform: translateX(5%);
}

// I
.lobbyBanner #Play_Online path:nth-child(9) {
  transition: all 1s 0.4s ease;
}

.lobbyBanner:hover #Play_Online path:nth-child(9) {
  transition: all 1s 0.4s ease;
  fill: #26ddff !important;
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
  transform: translateX(5%);
}

// N
.lobbyBanner #Play_Online path:nth-child(10) {
  transition: all 1s 0.45s ease;
}

.lobbyBanner:hover #Play_Online path:nth-child(10) {
  fill: #26ddff !important;
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
  transform: translateX(5%);
}

// G
.lobbyBanner #Play_Online path:nth-child(11) {
  transition: all 1s 0.5s ease;
}

.lobbyBanner:hover #Play_Online path:nth-child(11) {
  fill: #26ddff !important;
  filter: drop-shadow(0px 0px 0.2rem #26ddff);
  transform: translateX(5%);
}

#UFO-SlideWrapper .UFO-SlideIn {
  transition: all 1s 0.5s ease;
  transform: translate(-50%, 0%);
  position: absolute;
  animation: ufoSlideIn 2s 0s ease-in-out forwards, ufoScan 3s 2s infinite linear;
}

#UFO-SlideWrapper.left .UFO-SlideIn {
  animation: ufoLeft 0.3s 0s ease-in-out forwards;
}

@keyframes ufoLeft {
  0% {
    left: 25%;
    opacity: 0;
  }

  100% {
    opacity: 1;
    left: 25%;
  }
}

#UFO-SlideWrapper.right .UFO-SlideIn {
  animation: ufoRight 0.3s 0s ease-in-out forwards;
}

@keyframes ufoRight {
  0% {
    left: 75%;
    opacity: 0;
  }

  100% {
    opacity: 1;
    left: 75%;
  }
}

// .UFO-BounceX {
//   position : absolute;
//   animation: ufoBounceX 3s linear forwards;
// }

// .UFO-BounceR {
//   position : absolute;
//   animation: ufoBounceR 5s linear infinite;
// }

@keyframes ufoBounceR {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-10deg);
  }

  75% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes ufoBounceX {
  0% {
    opacity: 0;
    padding: 0%;
  }

  25% {
    opacity: 1;
  }

  75% {
  }

  100% {
    padding: 50%;
  }
}

@keyframes ufoSlideIn {
  0% {
    transform: scale(0.1, 0.1);
    left: 100%;
    top: 10rem;
  }

  50% {
    left: 10%;
    top: 10%;
  }

  100% {
    left: 25%;
    top: 10%;
  }
}

@keyframes ufoScan {
  0% {
    left: 25%;
  }

  35% {
    left: 25%;
  }

  50% {
    left: 75%;
  }

  85% {
    left: 75%;
  }

  100% {
    left: 25%;
  }
}

#ufoBeam {
  animation: ufoBeam 3s 2s ease-in-out infinite;
}

@keyframes ufoBeam {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  35% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  65% {
    opacity: 1;
  }

  85% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.SignUpOption1 {
  animation: SignUpOption 3s 1s ease-in-out infinite;
}

.SignUpOption2 {
  animation: SignUpOption 3s 2.5s ease-in-out infinite;
}

@keyframes SignUpOption {
  0% {
    text-shadow: 0px 0px 5px #fff !important;
    color: #fff;
  }

  40% {
    text-shadow: 0px 0px 5px #fff !important;
    color: #fff;
  }

  45% {
    text-shadow: 0px 0px 5px #26ddff !important;
    color: #26ddff;
  }

  55% {
    text-shadow: 0px 0px 5px #26ddff !important;
    color: #26ddff;
  }

  60% {
    text-shadow: 0px 0px 5px #fff !important;
    color: #fff;
  }

  100% {
    text-shadow: 0px 0px 5px #fff !important;
    color: #fff;
  }
}

.UFO-SlideIn path {
  filter: drop-shadow(0px 0px 1rem #26ddff);
}

#UFO-SlideWrapper.left .SignUpOption1,
#UFO-SlideWrapper.left .SignUpOption2,
#UFO-SlideWrapper.right .SignUpOption1,
#UFO-SlideWrapper.right .SignUpOption2 {
  animation: none;
}

#UFO-SlideWrapper.left .SignUpOption1,
#UFO-SlideWrapper.right .SignUpOption2 {
  color: #26ddff;
  text-shadow: 0px 0px 10px #26ddff !important;
}

.heart-rate {
  // width: 150px;
  //  height: 30px;
  position: relative;
  // margin: 20px auto;
}

.fade-in {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  top: 0;
  right: 0;
  animation: heartRateIn 2.5s linear infinite;
}

.fade-out {
  position: absolute;
  width: 120%;
  height: 100%;
  top: 0;
  left: -120%;
  animation: heartRateOut 2.5s linear infinite;
  background: #000;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
  background: -o-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
  background: -ms-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
}

@keyframes heartRateIn {
  0% {
    width: 100%;
  }

  50% {
    width: 0;
  }

  100% {
    width: 0;
  }
}

@keyframes heartRateOut {
  0% {
    left: -120%;
  }

  30% {
    left: -120%;
  }

  100% {
    left: 0;
  }
}

.react-json-view {
  word-break: break-all;
}

// For DateTimePicker Dialog
// #primary-search-account-menu+div {
//   top          : 50% !important;
//   left         : 50% !important;
//   transform    : translate(-50%, -50%) !important;
//   border-radius: 1rem;
// }

.calendarDialog {
  .MuiPaper-root {
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0px 0px 10px #26ddff !important;
    border: 1px solid #26ddff !important;
  }
  .MuiPaper-root > div {
    background-color: #000 !important;
  }

  .MuiPaper-root .MuiClockPicker-arrowSwitcher + div > div > div > div {
    box-shadow: 0px 0px 10px #26ddff !important;
  }

  .MuiPaper-root .MuiClockPicker-arrowSwitcher + div > div > div {
    box-shadow: 0px 0px 10px #26ddff !important;
  }

  // Today
  .MuiPaper-root .MuiPickersDay-today {
    color: #26ddff !important;
    border: none !important;
    box-shadow: 0px 0px 10px #26ddff66 !important;
    border: 1px solid #26ddff33 !important;
  }

  // Selected
  .MuiPaper-root .MuiPickersDay-root.Mui-selected {
    // background-color: #26ddff;
    // color           : #000;
    border: 1px solid #26ddff !important;
    box-shadow: 0px 0px 3px #26ddff !important;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%2326ddff'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 10px;
  background-position: calc(100% - 0.3rem) 0.9rem;
  background-repeat: no-repeat;
  background-color: #ff0;
}

.zIndex-1 {
  z-index: 1;
}
